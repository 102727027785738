import { escapeRegExp, get, orderBy } from 'lodash';
import { NexoyaPortfolioContentDetail, NexoyaPortfolioContentMetric } from '../../../../types';
import ContentTableRowTDM from './ContentTableRowTDM';
import * as Styles from '../../styles/ContentTableRow';
import { buildContentPath, buildKpiPath } from '../../../paths';
import TypographyTranslation from '../../../../components/TypographyTranslation';
import ButtonIcon from '../../../../components/ButtonIcon';
import { toast } from 'sonner';
import SvgCopyToClipboard from '../../../../components/icons/CopyToClipboard';
import { TagStyled } from '../../styles/OptimizationProposal';
import { nexyColors } from '../../../../theme';
import { ContentImpactGroupPanel } from './ContentImpactGroupsPanel';
import FormattedCurrency from '../../../../components/FormattedCurrency';
import Typography from '../../../../components/Typography';
import ContentMetricPanel from './ContentRule/assignment/ContentMetricPanel';
import React from 'react';
import { Link } from 'react-router-dom';
import { encodeDateQuery } from '../../../../utils/dates';
import dayjs from 'dayjs';
import SvgUnlink from '../../../../components/icons/Unlink';
import { ContentLabelPanel } from './ContentLabelPanel';
import ChildTDM from '../ChildTDM';
import { FilterBullet } from '../../../../components/PerformanceTable/styles';
import translate from '../../../../utils/translate';

const getKpiLink = (
  metric: NexoyaPortfolioContentMetric,
  item: NexoyaPortfolioContentDetail,
  startDate: Date,
  endDate: Date,
) =>
  buildKpiPath(
    {
      measurement_id: metric?.metricTypeId,
      collection_id: item?.contentId,
    },
    {
      // if start/end date are later than today, "crop" them to today
      dateFrom: encodeDateQuery(dayjs().isAfter(startDate) ? startDate : dayjs()),
      dateTo: encodeDateQuery(dayjs().isAfter(endDate) ? endDate : dayjs()),
    },
  );

export const getData = ({
  funnelSteps,
  content,
  filteredProviderIds,
  filteredLabelIds,
  filteredImpactGroupIds,
  hideDisabledContents,
  search,
  dateTo,
  dateFrom,
  translations,
  portfolio,
  getThemeColor,
}) => {
  const funnelStepIds = portfolio?.funnelSteps?.map((fs) => fs.funnel_step_id);

  return orderBy(content, 'isIncludedInOptimization', 'desc')
    .filter((c: NexoyaPortfolioContentDetail) => {
      const normalizedSearch = search?.trim().toLowerCase() || '';
      const contentTitle = (c.content?.title || '').toLowerCase();

      const searchTerms = normalizedSearch.split(/\s+/).map((term) => escapeRegExp(term));
      const regex = new RegExp(searchTerms.join('.*'), 'i');

      return (
        (filteredProviderIds.length ? filteredProviderIds.includes(c.content?.provider?.provider_id) : true) &&
        (filteredLabelIds.length ? filteredLabelIds.includes(c.label?.labelId) : true) &&
        (filteredImpactGroupIds.length ? filteredImpactGroupIds.includes(c.impactGroup?.impactGroupId) : true) &&
        (normalizedSearch ? regex.test(contentTitle) : true) &&
        (hideDisabledContents || c.isIncludedInOptimization)
      );
    })
    .map((item: NexoyaPortfolioContentDetail) => {
      const providerId = item?.content?.provider.provider_id;
      const metrics = get(item, 'metrics', []) || [];
      const defaultMetric: NexoyaPortfolioContentMetric = metrics.filter((item) => item.isOptimizationTarget)[0];

      const labelIndex = filteredLabelIds?.findIndex((labelId) => labelId === item.label?.labelId);

      const row = {
        highlight: !item.isIncludedInOptimization,
        editRow: <ContentTableRowTDM item={item} dateTo={dateTo} dateFrom={dateFrom} portfolio={portfolio} />,
        content: (
          <Styles.StyledLink
            key={item?.content?.title}
            to={buildContentPath(
              item.contentId,
              {
                dateFrom: portfolio.startDate.substring(0, 10),
                dateTo: portfolio.endDate.substring(0, 10),
              },
              true,
            )}
          >
            <Styles.AvatarWrapStyled providerId={providerId} size={24} condensed={true} style={{ marginRight: 12 }} />
            {defaultMetric ? (
              <Styles.ParentMetricWrap flexDirection="column">
                <Typography
                  component="p"
                  display="inline-block"
                  style={{
                    minWidth: 0,
                  }}
                >
                  {item?.content?.title}
                </Typography>
                <span className="contentType">{item?.content?.collectionType?.name || 'n/a'}</span>
              </Styles.ParentMetricWrap>
            ) : (
              <Typography style={{ color: '#000' }}>{item?.content?.title}</Typography>
            )}
            <ButtonIcon
              onClick={(e) => {
                e.preventDefault();
                navigator.clipboard
                  .writeText(translate(translations, item?.content?.title)?.trim())
                  .then(() => toast.message('Content copied to clipboard'));
              }}
              className="copyContentButton"
              title="Copy content name"
            >
              <SvgCopyToClipboard />
            </ButtonIcon>
          </Styles.StyledLink>
        ),
        contentLevel: (
          <div key={item.content.collectionType.name} className="flex h-full w-full items-center justify-center">
            <TagStyled bgColor="#eaeaea">{item.content.collectionType.name}</TagStyled>
          </div>
        ),
        optimization: (
          <div
            key={item.isIncludedInOptimization?.toString()}
            className="flex h-full w-full items-center justify-center"
          >
            <TagStyled
              style={{ maxWidth: 70 }}
              bgColor={item.isIncludedInOptimization ? '#88E7B7' : nexyColors.frenchGray}
            >
              {item.isIncludedInOptimization ? 'Enabled' : 'Disabled'}
            </TagStyled>
          </div>
        ),
        label: (
          <Styles.RowCell key={item?.label?.name} className="text-left">
            {filteredLabelIds?.length ? <FilterBullet backgroundColor={getThemeColor(labelIndex)} /> : null}
            <ContentLabelPanel
              content={item}
              dateFrom={dateFrom}
              dateTo={dateTo}
              portfolioId={portfolio?.portfolioId}
            />
          </Styles.RowCell>
        ),
        impactGroup: (
          <Styles.RowCell key={item?.impactGroup?.impactGroupId}>
            <ContentImpactGroupPanel
              content={item}
              dateFrom={dateFrom}
              dateTo={dateTo}
              portfolioId={portfolio?.portfolioId}
            />
          </Styles.RowCell>
        ),
        budgetLimit: (
          <Styles.WrapStyled className="flex flex-col items-center">
            {item?.budget?.min || item?.budget?.max ? (
              <>
                <FormattedCurrency amount={item?.budget?.min || item?.budget?.max} />
                <Styles.BudgetLabel>
                  {item?.budget?.min ? 'Minimum' : item?.budget?.max ? 'Maximum' : ''}
                </Styles.BudgetLabel>
              </>
            ) : null}
          </Styles.WrapStyled>
        ),
        bidStrategy: (
          <Styles.RowCell className="text-left">
            <Typography>{get(item, 'metadata.biddingStrategy', '')}</Typography>
          </Styles.RowCell>
        ),
        ...Object.fromEntries(
          funnelStepIds.map((funnelId: number) => {
            // finds metrics item for given funnel step
            const targetMetric = item.metrics.find((metric) => metric.funnelStep.funnel_step_id === funnelId);
            // checks if it has other metrics
            const hasOptionalTargetTypes = targetMetric?.otherFunnelStepMetrics?.otherMetrics;
            // default metric id
            const targetMetricId: number = targetMetric?.otherFunnelStepMetrics?.activeFunnelStepMetricId;
            const targetName: string = targetMetric?.coreMetricValues?.metricTypeName;

            // we display switch only if there is no child content for the specific funnel step id
            // one item -> multiple children, each child -> multiple metrics
            const hasNoChildTarget = !item.childContent.find((child) =>
              child.metrics.find((metric) => metric.funnelStep.funnel_step_id === funnelId && !!metric.metricTypeId),
            );
            // check if there is child metric for this exact funnel step
            const exactFunnelStepChild = item.childContent?.find((child) =>
              child.metrics.find((cm) => cm.funnelStep?.funnel_step_id === funnelId && !!cm.metricTypeId),
            );
            const childTargetMetric = exactFunnelStepChild?.metrics.find(
              (metric) => metric.funnelStep.funnel_step_id === funnelId,
            );

            return [
              `funnel_${funnelId}`,
              <Styles.RowCell key={funnelId} className="w-full">
                <ContentMetricPanel
                  item={item}
                  key={`metric-panel-${funnelId}`}
                  metricsForFunnelStep={targetMetric}
                  portfolioId={portfolio?.portfolioId}
                  funnelStepId={funnelId}
                  dateFrom={dateFrom}
                  dateTo={dateTo}
                  targetName={targetMetric && targetMetricId !== 0 ? targetName : 'None'}
                  link={
                    targetMetricId === 0
                      ? null
                      : exactFunnelStepChild
                        ? getKpiLink(childTargetMetric, exactFunnelStepChild, portfolio.startDate, portfolio.endDate)
                        : getKpiLink(targetMetric, item, portfolio.startDate, portfolio.endDate)
                  }
                  shouldRenderDropdown={hasOptionalTargetTypes && hasNoChildTarget}
                />
              </Styles.RowCell>,
            ];
          }),
        ),
        subRows: item.childContent?.map((child) => {
          const childMetrics: NexoyaPortfolioContentMetric[] = get(child, 'metrics', []) || [];
          const childDefaultMetric = childMetrics.filter((item) => item.isOptimizationTarget)[0];
          const childLabel = get(child, 'label.name', '');

          return {
            lightHighlight: true,
            editRow: (
              <ChildTDM
                portfolioId={portfolio?.portfolioId}
                dateTo={dateTo}
                dateFrom={dateFrom}
                contentId={child.contentId}
              />
            ),
            content: (
              <Styles.ChildContentWrap key={child?.content?.title} alignItems="center">
                <div>
                  <SvgUnlink />
                </div>
                <Styles.AvatarWrapStyled providerId={get(child, 'content.provider.provider_id', null)} size={24} />
                {childDefaultMetric ? (
                  <Styles.ChildAvatarWrapper
                    to={buildContentPath(
                      child.contentId,
                      {
                        dateFrom: portfolio.startDate.substring(0, 10),
                        dateTo: portfolio.endDate.substring(0, 10),
                      },
                      true,
                    )}
                  >
                    <Link
                      to={buildContentPath(
                        child.contentId,
                        {
                          dateFrom: portfolio.startDate.substring(0, 10),
                          dateTo: portfolio.endDate.substring(0, 10),
                        },
                        true,
                      )}
                    >
                      <TypographyTranslation
                        text={get(child, 'content.title', 'n/a')}
                        component="p"
                        display="inline-block"
                      />
                      <span className="contentType">{get(child, 'content.collectionType.name', 'n/a') || 'n/a'}</span>
                    </Link>
                  </Styles.ChildAvatarWrapper>
                ) : (
                  <Styles.PaddedLabel>
                    <Link
                      to={buildContentPath(
                        child.contentId,
                        {
                          dateFrom: portfolio.startDate.substring(0, 10),
                          dateTo: portfolio.endDate.substring(0, 10),
                        },
                        true,
                      )}
                    >
                      <TypographyTranslation
                        withTooltip
                        text={get(child, 'content.title', 'n/a')}
                        component="p"
                        display="inline-block"
                      />
                    </Link>
                  </Styles.PaddedLabel>
                )}
              </Styles.ChildContentWrap>
            ),
            label: (
              <Styles.RowCell key={item?.label?.name} className="text-left">
                <TypographyTranslation text={childLabel} component="div" display="inline-block" withTooltip={false} />
              </Styles.RowCell>
            ),
            ...Object.fromEntries(
              funnelStepIds.map((funnelId, idx) => {
                const targetMetric = childMetrics.find((metric) => metric.funnelStep.funnel_step_id === funnelId);
                const hasOptionalTargetTypes = !!targetMetric?.otherFunnelStepMetrics?.otherMetrics;
                const targetMetricId: number = get(
                  targetMetric,
                  'otherFunnelStepMetrics.activeFunnelStepMetricId',
                  null,
                );
                const targetName: string = get(targetMetric, 'coreMetricValues.metricTypeName', '');

                return [
                  `funnel_${funnelId}`,
                  <Styles.RowCell alignItems="space-between" key={funnelId} className="!justify-between gap-4">
                    <ContentMetricPanel
                      key={`metric-panel-${idx}`}
                      isChildContent
                      item={child}
                      metricsForFunnelStep={targetMetric}
                      portfolioId={portfolio?.portfolioId}
                      funnelStepId={funnelId}
                      dateFrom={dateFrom}
                      dateTo={dateTo}
                      targetName={targetMetricId === 0 ? 'None' : targetName}
                      link={
                        targetMetric && targetMetricId !== 0
                          ? getKpiLink(targetMetric, child, portfolio.startDate, portfolio.endDate)
                          : null
                      }
                      shouldRenderDropdown={hasOptionalTargetTypes}
                    />
                  </Styles.RowCell>,
                ];
              }),
            ),
          };
        }),
      };

      funnelSteps.forEach((funnelStep) => {
        row[funnelStep.funnel_step_id] = funnelStep.title;
      });

      return row;
    });
};
